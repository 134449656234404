import { ref, onMounted, onUnmounted } from 'vue';

export default function useAnchor() {
  const anchor = ref(window.location.hash.slice(1));
  const updateAnchor = () => {
    anchor.value = window.location.hash.slice(1);
  };

  onMounted(() => {
    window.addEventListener('hashchange', updateAnchor, false);
  });

  onUnmounted(() => {
    window.removeEventListener('hashchange', updateAnchor);
  });

  return anchor;
}
