<template>
  <section v-view class="leistungen part">
    <div class="constrain">
      <div class="row-12">
        <div class="col-12 md:col-5 md:offset-1">
          <div class="animate reveal">
            <slider
              ref="sliderRef"
              v-slot="{ item: image }"
              :slides-per-view="{ desktop: 1, mobile: 1 }"
              :slides-per-group="{ desktop: 1, mobile: 1 }"
              :items="images"
              effect="fade"
              @progress="onProgress"
              @afterInit="updatePage"
              @slideChange="updatePage"
              @resize="updatePage"
              @update="updatePage"
            >
              <box class="image-box" force-aspect-ratio no-padding>
                <Image fade-swap :src="image" />
              </box>
            </slider>
          </div>
          <page-progress
            v-if="!isDesktop"
            class="progress"
            :start="start"
            :end="end"
            :current-page="page"
            :pages="pages"
            @prev="prev"
            @next="next"
          />
        </div>
        <div class="col-12 md:col-5 md:offset-1 services">
          <accordion v-model="expanded" class="service-list" :items="payload.services">
            <template #header="{ item: service, expanded }">
              <div
                class="summary"
                :class="{ expanded }"
                @mouseenter="showService(service)"
                @mouseleave="resetService()"
              >
                <h3 v-html="service.titel" />
                <arrow-down v-if="!isDesktop" />
              </div>
            </template>
            <template #default="{ item: service }">
              <div class="text" v-html="service.beschreibung" />
            </template>
          </accordion>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  computed, watch, ref, onMounted,
} from 'vue';
import ArrowDown from '@/assets/images/arrow_down.svg?inline';
import { usePage, useVisibleProgress } from '@/composables/swiper';
import { useWithinBreakpoint } from '@/composables/breakpoints';
import useAnchor from '@/composables/anchor';
import PageProgress from '../utils/PageProgress.vue';

export default {
  components: { ArrowDown, PageProgress },
  props: { payload: { type: Object, default: () => ({}) } },
  setup(props) {
    const expanded = ref([]);
    const forcedService = ref(null);

    const sliderRef = ref(null);

    const anchor = useAnchor();

    const { onProgress, start, end } = useVisibleProgress();
    const { updatePage, page, pages } = usePage();

    const prev = () => sliderRef.value?.prev();
    const next = () => sliderRef.value?.next();

    const isDesktop = useWithinBreakpoint('md');

    const showService = (service) => {
      if (service.bilder?.length) {
        forcedService.value = service;
      }
    };
    const resetService = () => {
      forcedService.value = null;
    };
    const images = computed(() => {
      if (forcedService.value) {
        return forcedService.value.bilder;
      }
      const filteredImages = props.payload?.services
        .filter(
          (service) => !expanded.value.length || expanded.value.find(({ id }) => service.id === id),
        )
        .flatMap((service) => service.bilder);

      if (!filteredImages.length) {
        return props.payload?.services?.flatMap((s) => s.bilder);
      }

      return filteredImages;
    });

    watch(expanded, () => {
      forcedService.value = null;
    });

    const adjustToAnchor = (newAnchor) => {
      if (newAnchor) {
        const service = props.payload?.services.find((s) => s.slug === newAnchor);
        if (service) {
          showService(service);
          expanded.value = [service];
        }
      }
    };

    onMounted(() => {
      adjustToAnchor(anchor.value);
    });

    watch(anchor, adjustToAnchor);

    return {
      images,
      expanded,
      showService,
      resetService,
      onProgress,
      start,
      end,
      updatePage,
      page,
      pages,
      prev,
      next,
      sliderRef,
      isDesktop,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.leistungen {
  @screen md {
    margin-top: calc(var(--top-margin) * -1);
    height: 100vh;
    display: flex;
    flex: 0 0 auto;

    & > :first-child {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

.leistungen:last-of-type {
  @include responsive("margin-bottom", px(74), unset);
}

.services {
  display: flex;
  flex-flow: column;
  justify-content: center;

  .service-list {
    --highlight-color: var(--color-blue-medium);
    @include responsive("margin-top", px(28), 0);

    .summary {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      cursor: pointer;

      @include responsive("padding", px(10) 0, px(13.5) 0);
      @include responsive("pointer-events", none, all);

      h3 {
        margin: 0;
        transition: padding var(--speed-fast) ease;
      }

      @media (pointer: fine) {
        &:hover {
          @apply text-orange;
        }
      }

      svg {
        width: px(30);
        height: px(30);
        color: black;
        will-change: transform;
        transition: transform var(--speed-fast) ease;

        :deep(path),
        :deep(circle) {
          stroke: currentColor;
        }
      }

      &.expanded svg {
        transform: rotate(180deg);
      }
    }

    :deep(.dropdown) {
      @include responsive("border-top", 1px solid var(--highlight-color), none);
      &:last-child {
        @include responsive("border-bottom", 1px solid var(--highlight-color), none);
      }

      &[aria-expanded="true"] {
        --highlight-color: var(--color-primary);

        & + .dropdown {
          border-top-color: var(--color-primary);
        }
      }
    }

    .text {
      opacity: 0;
      transition: opacity var(--speed-fast) ease;
      @include responsive("padding", px(4) 0 px(19) 0, px(3) 0 px(64.5) 0);
      @include responsive("margin-left", 0, px(50));
    }

    & [aria-expanded="false"] {
      @media (pointer: fine) {
        .summary {
          position: relative;

          h3::before {
            content: "+";
            position: absolute;
            left: 0;
            opacity: 0;
            transition:
              transform var(--speed-fast) ease,
              opacity var(--speed-fast) ease;
            transform: translateX(-1rem);
          }
          &:hover {
            h3::before {
              opacity: 1;
              transform: translateX(-3rem);
            }
          }
        }
      }
    }

    & [aria-expanded="true"] {
      h3 {
        @apply text-primary;
        padding-left: 0px;

        &::before {
          content: "-";
          position: absolute;
          left: 0;
          opacity: 0;
          transition: opacity var(--speed-fast) ease;
        }

        @screen md {
          position: relative;
          padding-left: px(50);

          &::before {
            opacity: 1;
          }
        }
      }

      .text {
        opacity: 1;
      }
    }
  }
}

.image-box {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.progress {
  @include responsive("margin-top", px(10), 0);
}

.reveal {
  @apply rounded-big;
  overflow: hidden;
  width: 100%;
  height: max-content;
}
</style>
